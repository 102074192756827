export const VALID_GUESSES = [
    'arswed',
    'brdfut',
    'shaweb',
    'kulhan',
    'kamwon',
    'kulpuc',
    'shimar',
    'shadeb',
    'diginc',
    'bvnweb',
    'kulcto',
    'saptal',
    'djapla',
    'bummar',
    'swerin',
    'coghop',
    'kulebo',
    'limnat',
    'metweb',
    'comdir',
    'vesref',
    'veszel',
    'vesmee',
    'diadis',
    'elffor',
    'mmmweb',
    'rismar',
    'basweb',
    'assmob',
    'kulist',
    'kuldue',
    'kulatt',
    'itertc',
    'extopl',
    'wavweb',
    'leudis',
    'ecosav',
    'kulzus',
    'aanweb',
    'camweb',
    'sifssu',
    'vigroo',
    'leulev',
    'svmwec',
    'eighyd',
    'kulcic',
    'igkaud',
    'emsmar',
    'pocweb',
    'eneweb',
    'comcar',
    'assweb',
    'enemar',
    'ovbweb',
    'eitwec',
    'kulcob',
    'kulvai',
    'kuloff',
    'kulica',
    'ctrweb',
    'flabga',
    'uclweb',
    'kullcy',
    'hotexp',
    'cmtven',
    'comsoc',
    'kulles',
    'kulver',
    'argzoo',
    'datweb',
    'vesfor',
    'zelfos',
    'ccvweb',
    'sifcrm',
    'kulswo',
    'kuldar',
    'zzzdig',
    'kulxmt',
    'beljob',
    'silfam',
    'eyfweb',
    'ranweb',
    'kuldoc',
    'wvvpla',
    'kuliiw',
    'kuldeo',
    'klekna',
    'kulvac',
    'kullpi',
    'supeen',
    'reploh',
    'repgui',
    'repair',
    'repmap',
    'prnlev',
    'mmiweb',
    'kulweb',
    'kulalv',
    'kulsig',
    'boeboe',
    'hotpar',
    'ubgweb',
    'repcit',
    'ljwsho',
    'sifrad',
    'shldig',
    'deldig',
    'omnpla',
    'kikkoo',
    'uvvvla',
    'kullbi',
    'neodig',
    'igkdat',
    'vesweb',
    'kulsea',
    'omhmar',
    'azvdag',
    'uclinf',
    'lucinf',
    'avpweb',
    'kulste',
    'fibmar',
    'stuplb',
    'flymoi',
    'sifweb',
    'pleweb',
    'olyapp',
    'cisweb',
    'aixwec',
    'vafweb',
    'ptrnat',
    'frtspi',
    'kulibd',
    'cmtweb',
    'mazond',
    'kulalu',
    'viglee',
    'vigont',
    'almbrq',
    'bdbweb',
    'picweb',
    'vwvins',
    'kulerf',
    'knxbus',
    'wvvweb',
    'almwec',
    'kulglo',
    'bigtui',
    'kulgro',
    'culvir',
    'kulfoc',
    'shicla',
    'vlhweb',
    'strweb',
    'ftidag',
    'ruiwec',
    'limkli',
    'cammar',
    'bigfor',
    'vsfmar',
    'guavdp',
    'vlbweb',
    'culstr',
    'asslev',
    'leqweb',
    'kulddd',
    'kulsts',
    'pubpro',
    'cebweb',
    'intmaa',
    'kullki',
    'kulbrp',
    'kikweb',
    'kihdig',
    'itzwec',
    'socweb',
    'pubuit',
    'shamov',
    'kulmag',
    'kulath',
    'cerfig',
    'vluint',
    'kuledu',
    'minsav',
    'dcxsel',
    'dtjweb',
    'facmar',
    'hervsc',
    'intwec',
    'kuljop',
    'kullat',
    'mazfor',
    'mtmwec',
    'vlbmys',
    'wttnes',
    'cullee',
    'shamen',
    'shaall',
    'replog',
    'lknadm',
    'mincre',
    'sealea',
    'vubwet',
    'zelfor',
    'youweb',
    'youjob',
    'pubkam',
    'lokweb',
    'pubbil',
    'pubdat',
    'leuwil',
    'kulmai',
    'seaplb',
    'mobciv',
    'mktpla',
    'pasver',
    'vhgapp',
    'leucum',
    'ldosho',
    'shaomg',
    'leukab',
    'lerwec',
    'minweb',
    'leumij',
    'vetweb',
    'nrfwec',
    'taazoe',
    'stuwec',
    'paswec',
    'papweb',
    'rafweb',
    'seapla',
    'mazdea',
    'sesweb',
    'traweb',
    'vigbov',
    'vigtie',
    'gsfpla',
    'kularc',
    'culleu',
    'clbweb',
    'gloweb',
    'kulcul',
    'kulfib',
    'attbpo',
    'cerfif',
    'duvwec',
    'bioweb',
    'kulgen',
    'kulbir',
    'cpiweb',
    'kullcb',
    'areweb',
    'conweb',
    'kuleco',
    'gimweb',
    'hemweb',
    'hiving',
    'karweb',
    'gsfbex',
    'fvbwec',
    'erfdat',
    'hanweb',
    'forweb',
    'crksta',
    'hasmar',
    'knxweb',
    'knxpro',
    'knxmag',
    'depwee',
    'igkver',
    'kuldyn',
    'kleare',
    'gsfwec',
    'agrsvm',
    'almbrp',
    'abewec',
    'bigbos',
    'intfic',
    'vplweb',
    'vlukwa',
    'culant',
    'culbru',
    'vlusib',
    'vlusif',
    'olypla',
    'kulcar',
    'culgen',
    'btsweb',
    'kulpal',
    'pubweb',
    'vluweb',
    'movpla',
    'djawel',
    'vigpeu',
    'kulber',
    'mobsch',
    'parweb',
    'brogul',
    'watwec',
    'azdweb',
    'intcra',
    'abbweb',
    'samweb',
    'mumtaa',
    'cvtweb',
    'ihlweb',
    'klewec',
    'telpla',
    'unisup',
    'kulnie',
    'leupar',
    'kulden',
    'attzaa',
    'attwar',
    'vefweb',
    'kulibo',
    'trestf',
    'vubadm',
    'grfweb',
    'eursho',
    'stuaru',
    'duvstu',
    'agtweb',
    'wsbweb',
    'woosho',
    'eitweb',
    'kulkan',
    'vwvweb',
    'fshweb',
    'sibinf',
    'voller',
    'cascad',
    'mumgid',
    'tzhweb',
    'itzinc',
    'itzitr',
    'itzweb',
    'lucnac',
    'fmcnit',
    'limnie',
    'cegprp',
    'cegpro',
    'mrktop',
    'uneweb',
    'dspssi',
    'greweb',
    'fnging',
    'fngcon',
    'fngins',
    'musnie',
    'vaaond',
    'vaaweb',
    'yhcweb',
    'kulori',
    'munweb',
    'kuleda',
    'kulcon',
    'kulluf',
    'kulhui',
    'lemweb',
    'vlpwec',
    'kuleng',
    'kuljob',
    'jaswec',
    'kulxms',
    'kulbro',
    'kulfeb',
    'ectair',
    'kulhiw',
    'kulfiw',
    'wegweb',
    'okaweb',
    'monweb',
    'kulpar',
    'kuliup',
    'igkdyn',
    'binweb',
    'kulcak',
    'kulkal',
    'creapp',
    'kleuto',
    'leucul',
    'atmweb',
    'leusch',
    'pivmar',
    'toxaca',
    'ruiweb',
    'dcxind',
    'dcxgrp',
    'dcxlog',
    'dcxmov',
    'duvned',
    'duvhop',
    'duvweb',
    'gsfmij',
    'gsfweb',
    'kulsvz',
    'medheb',
    'hirweb',
    'watibh',
    'iriweb',
    'javsho',
    'javweb',
    'javwed',
    'javwee',
    'kfdweb',
    'kfdwec',
    'pubmus',
    'efaeff',
    'hvnpat',
    'almcat',
    'kbvpla',
    'okradm',
    'kulint',
    'kdkweb',
    'lerweb',
    'heloor',
    'meeweb',
    'mtfweb',
    'mirweb',
    'crkmus',
    'fmcweb',
    'nrfweb',
    'areolu',
    'opeweb',
    'opcpar',
    'renpro',
    'pactra',
    'quaweb',
    'rdvweb',
    'rvoweb',
    'seawec',
    'seaweb',
    'arswec',
    'arsweb',
    'henweb',
    'viaweb',
    'medvro',
    'ronweb',
    'ronwec',
    'vlbzak',
    'simweb',
    'staweb',
    'tisweb',
    'toxlea',
    'triweb',
    'leuuit',
    'igkweb',
    'kleutp',
    'kleves',
    'vaddru',
    'vadweb',
    'kulhyf',
    'treste',
    'leusta',
    'watvod',
    'watkro',
    'watweb',
    'gamsho',
    'incthr',
    'wicweb',
    'jubweb',
    'cvtwin',
    'yhvweb',
    'zdhweb',
    'silweb',
    'cenweb',
    'youcrm',
    'taacna',
    'belweb',
    'almbro',
    'almweb',
    'renweb',
    'mtmweb',
    'mobkrc',
    'mobsam',
    'mobmor',
    'mobweb',
    'fvbweb',
    'pasweb',
    'jobpar',
    'jobloo',
    'jobweb',
    'dcxren',
    'iclweb',
    'erfzoe',
    'erfweb',
    'ntgarc',
    'vggcha',
    'svmweb',
    'jasweb',
    'vubkun',
    'sheweb',
    'vvgand',
    'praweb',
    'ldoweb',
    'kvdweb',
    'etwweb',
    'ljaweb',
    'mooweb',
    'totnat',
    'nattoo',
    'decweb',
    'vmcweb',
    'lodweb',
    'vafvla',
    'dasweb',
    'denweb',
    'steweb',
    'iwaweb',
    'depweb',
    'depwec',
    'totdij',
    'dvoweb',
    'smiweb',
    'cypweb',
    'crkpar',
    'crkweb',
    'corcon',
    'davjon',
    'cmaweb',
    'cidweb',
    'chaweb',
    'celweb',
    'cazweb',
    'ljwmar',
    'commar',
    'finmar',
    'ligmar',
    'picmar',
    'dcxsho',
    'dqsweb',
    'benweb',
    'creweb',
    'knxmar',
    'trabdc',
    'remsmi',
    'ptrmbl',
    'budweb',
    'mazwie',
    'bijweb',
    'bapweb',
    'asmweb',
    'afoweb',
    'aixweb',
    'bkobru',
    'bkoweb',
    'bkokvk',
    'lasweb',
    'stuarc',
    'stupla',
    'stuart',
    'stuweb',
    'ardweb',
    'aerver',
    'aermak',
    'abeweb',
    'vigool',
    'vigerk',
    'viggez',
    'vigwec',
    'vigenq',
    'vigweb',
    'mkttov',
    'mktexp',
    'mktweb',
    'cerfie',
    'dcxmow',
    'statik'
]